import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer } from "@mui/material";
import { ReactComponent as Close } from "../../../../assets/icons/X.svg";
import { ReactComponent as Arrow_left } from "../../../../assets/icons/ArrowLeft.svg";
import {
  createNewObjective,
  editObjective,
  getObjectivesDrawerTableData,
  handleCurrentRowToEdit,
  falseCurrentProgressDisabledStates,
  toggleSideMenu,
} from "../../../../redux/slices/ObjectivesSlice";
import ObjectiveForm from "./ObjectiveForm";
import KeyResultsForm from "./KeyResultsForm";
import ActionButtons from "./ActionButtons";

const ObjectivesSideMenu = () => {
  const dispatch = useDispatch();

  const { isSideMenuOpen, currentProgressDisabledStates } = useSelector(
    (state) => state.objectivesReducer.sideMenu,
  );
  const { currentRowToEdit } = useSelector((state) => state.objectivesReducer);
  const tenantId = useSelector((state) => state.authReducer.user.tenantId);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isEditAllowed, setIsEditAllowed] = useState(true);
  const [objectiveType, setObjectiveType] = useState(null);
  const [keyResultsTableType, setKeyResultsTableType] = useState(null);
  const [objectivesDropdownData, setObjectivesDropdownData] = useState({});
  const [objectivesChecked, setObjectivesChecked] = useState([]);
  const [objectiveForm, setObjectiveForm] = useState({
    name: "",
    description: "",
    owner: "",
    update_frequency: "",
    start_date: null,
    end_date: null,
  });
  const [keyResultsList, setKeyResultsList] = useState([
    {
      name: "",
      threshold_type: "",
      threshold_value: "",
      current_value: "",
      KeyResultFor: [],
      auto_track: false,
    },
  ]);
  const [objectiveOwner, setObjectiveOwner] = useState(null);
  const [addPeopleToObjectiveSelected, setAddPeopleToObjectiveSelected] =
    useState([]);

  const getInputFieldsStyles = () => {
    return {
      background:
        showConfirmationModal || !isEditAllowed || currentProgressDisabledStates
          ? "#F8F9FA"
          : "#FFFFFF",
      color: (showConfirmationModal || !isEditAllowed) && "#6C757D",
      pointerEvents: (showConfirmationModal || !isEditAllowed) && "none",
    };
  };

  const renderIcon = () => {
    const commonIconProps = {
      stroke: "#343A40",
      strokeWidth: "2",
      style: {
        margin: "0px 20px 3px 0",
        width: "24px",
        cursor: "pointer",
      },
    };

    if (showConfirmationModal) {
      return (
        <Arrow_left
          {...commonIconProps}
          onClick={() => setShowConfirmationModal(false)}
        />
      );
    }

    return <Close {...commonIconProps} />;
  };

  const hanldeResetStates = () => {
    setShowConfirmationModal(false);
    setIsEditAllowed(true);
    setObjectiveForm({
      name: "",
      owner: "",
      description: "",
      update_frequency: "",
      start_date: null,
      end_date: null,
    });
    setAddPeopleToObjectiveSelected([]);
    setKeyResultsList([
      {
        name: "",
        threshold_type: "",
        threshold_value: "",
        current_value: "",
        KeyResultFor: [],
      },
    ]);
    setObjectiveOwner({});
    setObjectivesChecked([]);
    dispatch(handleCurrentRowToEdit({}));
    dispatch(falseCurrentProgressDisabledStates());
  };

  const handleSideMenuClose = () => {
    hanldeResetStates();
    dispatch(falseCurrentProgressDisabledStates());
    dispatch(toggleSideMenu());
  };

  const getInputFieldHeadingTextColor = () => {
    if (showConfirmationModal || !isEditAllowed) {
      return "grey6";
    } else {
      return "grey8";
    }
  };

  const handleCreateOKR = async () => {
    const objectivePayload = {
      ...objectiveForm,
      owner: objectiveOwner[0].value,
      tenant_id: tenantId,
      userObjectives: [],
      teamObjectives: [],
      departmentObjectives: [],
    };

    addPeopleToObjectiveSelected.forEach((item) => {
      const match = item.value.match(/(user|team|department)(\d+)/);
      if (match) {
        const prefix = match[1];
        const id = parseInt(match[2], 10);

        if (prefix === "user") {
          objectivePayload.userObjectives.push({ user_id: id });
        } else if (prefix === "team") {
          objectivePayload.teamObjectives.push({ team_id: id });
        } else if (prefix === "department") {
          objectivePayload.departmentObjectives.push({ department_id: id });
        }
      }
    });

    //key results
    const keyResults = keyResultsList.map((keyResult) => {
      const userKRs = [];
      const teamKRs = [];
      const departmentKRs = [];

      keyResult.KeyResultFor.forEach((item) => {
        const match = item.value.match(/(user|team|department)(\d+)/);
        if (match) {
          const prefix = match[1];
          const id = parseInt(match[2], 10);

          if (prefix === "user") {
            userKRs.push({
              user_id: id,
              value: parseInt(
                keyResult.current_value ? keyResult.current_value : 0,
              ),
            });
          } else if (prefix === "team") {
            teamKRs.push({
              team_id: id,
              value: parseInt(
                keyResult.current_value ? keyResult.current_value : 0,
              ),
            });
          } else if (prefix === "department") {
            departmentKRs.push({
              department_id: id,
              value: parseInt(
                keyResult.current_value ? keyResult.current_value : 0,
              ),
            });
          }
        }
      });

      return {
        name: keyResult.name,
        threshold_type: keyResult.threshold_type,
        threshold_value: parseInt(keyResult.threshold_value),
        auto_track: false,
        userKRs,
        teamKRs,
        departmentKRs,
      };
    });

    objectivePayload.keyresults = keyResults;
    Object.keys(currentRowToEdit).length
      ? dispatch(
          editObjective({ ...objectivePayload, id: currentRowToEdit.id }),
        )
      : dispatch(createNewObjective(objectivePayload));
    hanldeResetStates();
  };

  useEffect(() => {
    if (Object.keys(currentRowToEdit).length > 0) {
      setObjectiveForm({
        name: currentRowToEdit.name,
        owner: currentRowToEdit.owner,
        description: currentRowToEdit.description,
        update_frequency: currentRowToEdit.update_frequency,
        start_date: currentRowToEdit.start_date,
        end_date: currentRowToEdit.end_date,
      });

      setObjectiveOwner([
        {
          value: currentRowToEdit?.ownerprofile?.user_id,
          label:
            currentRowToEdit?.ownerprofile?.first_name +
            " " +
            currentRowToEdit?.ownerprofile?.last_name,
        },
      ]);

      const usersSelected = currentRowToEdit?.users?.map((user) => ({
        value: "user" + user.user_id,
        label: user.first_name + " " + user.last_name,
      }));

      const teamsSelected = currentRowToEdit?.teams?.map((team) => ({
        value: "team" + team.id,
        label: team.name,
      }));

      const departementsSelected = currentRowToEdit?.department?.map(
        (dept) => ({
          value: "department" + dept.id,
          label: dept.name,
        }),
      );

      const usersSelectedChecked = currentRowToEdit?.users?.map(
        (user) => "user" + user.user_id,
      );

      const teamsSelectedChecked = currentRowToEdit?.teams?.map(
        (team) => "team" + team.id,
      );

      const departementsSelectedChecked = currentRowToEdit?.department?.map(
        (dept) => "department" + dept.id,
      );

      setObjectivesChecked([
        ...usersSelectedChecked,
        ...teamsSelectedChecked,
        ...departementsSelectedChecked,
      ]);

      setAddPeopleToObjectiveSelected([
        ...usersSelected,
        ...teamsSelected,
        ...departementsSelected,
      ]);

      setKeyResultsList(
        currentRowToEdit.keyresults.map((keyresult) => ({
          name: keyresult.name,
          threshold_type: keyresult.threshold_type,
          threshold_value: keyresult.threshold_value,
          current_value: keyresult.current_value,
          /* eslint-disable no-unsafe-optional-chaining */
          KeyResultFor: [
            ...keyresult?.userKRs?.map((user) => ({
              value: "user" + user.user_id,
              label: user?.user[0]?.first_name + " " + user?.user[0]?.last_name,
            })),
            ...keyresult?.teamKRs?.map((team) => ({
              value: "team" + team.team_id,
              label: team?.team[0]?.name,
            })),
            ...keyresult?.departmentKRs?.map((dept) => ({
              value: "department" + dept.department_id,
              label: dept?.department[0]?.name,
            })),
          ],
          checked: [
            ...keyresult?.userKRs?.map((user) => "user" + user.user_id),
            ...keyresult?.teamKRs?.map((team) => "team" + team.team_id),
            ...keyresult?.departmentKRs?.map(
              (dept) => "department" + dept.department_id,
            ),
          ],
        })),
      );
    } else {
      setKeyResultsList([
        {
          name: "",
          threshold_type: "",
          current_value: "",
          threshold_value: "",
          KeyResultFor: [],
        },
      ]);
      setObjectiveForm({
        name: "",
        owner: "",
        description: "",
        update_frequency: "",
        start_date: null,
        end_date: null,
      });
      setAddPeopleToObjectiveSelected([]);
      setObjectivesChecked([]);
    }
  }, [currentRowToEdit, isSideMenuOpen]);

  useEffect(() => {
    if (currentProgressDisabledStates) return;
    const fetchData = async () => {
      const loadData = async (dataKey, apiCall) => {
        try {
          const response = await dispatch(apiCall).unwrap();
          setObjectivesDropdownData((prev) => ({
            ...prev,
            [dataKey]: response,
          }));
        } catch (error) {
          console.error(`Failed to fetch ${dataKey}`, error);
          // Optionally set a default value
          setObjectivesDropdownData((prev) => ({ ...prev, [dataKey]: [] }));
        }
      };

      loadData(
        "objectives_departments",
        getObjectivesDrawerTableData("objectives_departments"),
      );
      loadData(
        "objectives_teams",
        getObjectivesDrawerTableData("objectives_teams"),
      );
      loadData(
        "objectives_user_profile",
        getObjectivesDrawerTableData("objectives_user_profile"),
      );
    };

    fetchData();
  }, [objectiveType]);

  return (
    <Drawer
      anchor="right"
      open={isSideMenuOpen}
      onClose={() => handleSideMenuClose()}
      classes={{ paper: "main-drawer" }}
    >
      <div>
        <div
          className="d-flex justify-content-between"
          style={{ marginBottom: "22px" }}
        >
          <span
            className="head4 grey8 d-flex align-items-center"
            style={{ fontWeight: "400" }}
          >
            {renderIcon()}
            {"Create Objective"}
          </span>

          <div
            className="d-flex align-items-center flex-row-reverse"
            style={{ width: "fit-content" }}
          >
            <ActionButtons
              handleSideMenuClose={handleSideMenuClose}
              name={objectiveForm.name}
              isEditAllowed={isEditAllowed}
              setIsEditAllowed={setIsEditAllowed}
              isConfirmationModal={showConfirmationModal}
              setIsConfirmationModal={setShowConfirmationModal}
              handleCreateOKR={handleCreateOKR}
            />
          </div>
        </div>

        {showConfirmationModal && (
          <>
            <div
              style={{
                borderTop: "1px solid #BFBFBF",
                margin: "32px -48px",
              }}
            />
            <div className="head6-medium grey6"> Events Details</div>
          </>
        )}
        <div
          className="mt-2"
          style={{
            pointerEvents: showConfirmationModal || (!isEditAllowed && "none"),
          }}
        ></div>
        <ObjectiveForm
          showConfirmationModal={showConfirmationModal}
          isEditAllowed={isEditAllowed}
          objectiveForm={objectiveForm}
          setObjectiveForm={setObjectiveForm}
          getInputFieldsStyles={getInputFieldsStyles}
          getInputFieldHeadingTextColor={getInputFieldHeadingTextColor}
          objectiveType={objectiveType}
          setObjectiveType={setObjectiveType}
          addPeopleToObjectiveSelected={addPeopleToObjectiveSelected}
          setAddPeopleToObjectiveSelected={setAddPeopleToObjectiveSelected}
          objectiveOwner={objectiveOwner}
          setObjectiveOwner={setObjectiveOwner}
          objectivesDropdownData={objectivesDropdownData}
          objectivesChecked={objectivesChecked}
          currentProgressDisabledStates={currentProgressDisabledStates}
        />
        <KeyResultsForm
          showConfirmationModal={showConfirmationModal}
          isEditAllowed={isEditAllowed}
          getInputFieldsStyles={getInputFieldsStyles}
          getInputFieldHeadingTextColor={getInputFieldHeadingTextColor}
          keyResultsList={keyResultsList}
          setKeyResultsList={setKeyResultsList}
          isEditableState={isEditAllowed}
          isConfirmationModal={showConfirmationModal}
          objectiveType={objectiveType}
          keyResultsTableType={keyResultsTableType}
          setKeyResultsTableType={setKeyResultsTableType}
          objectivesDropdownData={objectivesDropdownData}
          currentProgressDisabledStates={currentProgressDisabledStates}
        />
      </div>
    </Drawer>
  );
};

export default ObjectivesSideMenu;
