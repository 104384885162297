import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../components/Custom/Modal/Modal";
import EditUser from "../../../components/SideMenus/EditUser";
import BackgroundBlur from "../../../components/sub-components/Blur";
import { Table } from "../../../components/Tables/Table";
// import TestTable from "../../../components/Tables/Table";
import {
  setTableSelectedRows,
  setUsersTablePage,
  toggleSideMenu,
  getUsersList,
  usersMovedToActiveSuccessAlert,
  handleArchiveUsersSlice,
} from "../../../redux/slices/usersSlice";
import { showSnackbar } from "../../../redux/slices/authSlice";
import { USERS_COLUMNS } from "../../../utilities/Constants";

function ActiveUsers(props) {
  const { inputValue, filters } = props;
  const { data, page, selected, tableLoading } = useSelector(
    (state) => state.usersReducer.tableData,
  );
  const { isSideMenu, usersMovedToArchiveSuccess } = useSelector(
    (state) => state.usersReducer,
  );
  const { userId } = useSelector((state) => state.authReducer.user);
  const [currentrow, setCurrentrow] = useState({});
  const [lastAddedUser, setLastAddedUser] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isModal, setIsModal] = useState(false);
  // const [userNotAlowedToArchive, setUserNotAlowedToArchive] = useState(false);
  const [isMultipleUsersSelected, setIsMultipleUsersSelected] = useState(false);

  const dispatch = useDispatch();

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCurrentRow = (row) => {
    setCurrentrow(row);
  };

  const handleSideMenu = () => {
    dispatch(toggleSideMenu());
  };

  const handleToolbarAction = () => {
    setIsModal(!isModal);
  };

  const handleArchiveModal = () => {
    handleActionMenuClose();
    setIsModal(!isModal);
  };

  const handleArchiveEditMenu = () => {
    handleActionMenuClose();
    dispatch(toggleSideMenu());
  };

  const handleModalClose = () => {
    setIsModal(!isModal);
  };

  const handleModalConfirmation = () => {
    setIsModal(!isModal);
    handleArchiveUsers();
  };

  const handleArchiveUsers = () => {
    let selectedUsersId = [];
    if (selected.length > 0 && !isSideMenu) {
      setIsMultipleUsersSelected(true);
      selectedUsersId = selected.map((users) => users.user_id);
    }
    // if user is selected from side menu OR multiple users are selected and side menu is open
    if (
      (selected.length === 0 && isSideMenu) ||
      (selected.length > 0 && isSideMenu)
    ) {
      setIsMultipleUsersSelected(false);
      setLastAddedUser(currentrow);
      selectedUsersId = [currentrow.user_id];
    }

    const filteredActiveUsers = data.filter(
      (user) => !selectedUsersId.includes(user.user_id),
    );

    const isMultipleRowsSelected = selected.length > 0 && !isSideMenu;

    if (userId === selectedUsersId[0] || selectedUsersId.includes(userId)) {
      // setUserNotAlowedToArchive(true);
      dispatch(
        showSnackbar({
          message:
            "Current logged in user is not allowed to moved to archive users",
          severity: "error",
        }),
      );
      dispatch(setUsersTablePage(1));
      dispatch(setTableSelectedRows([]));
      if (isSideMenu) {
        handleSideMenu();
      }
      return;
    }

    dispatch(setUsersTablePage(1));
    dispatch(setTableSelectedRows([]));
    dispatch(
      handleArchiveUsersSlice(
        selectedUsersId,
        filteredActiveUsers,
        isMultipleRowsSelected,
      ),
    );
  };

  const setTablePage = (page) => {
    dispatch(setUsersTablePage(page));
  };

  const setSelected = (data) => {
    dispatch(setTableSelectedRows(data));
  };

  const handleTableRowClick = (row) => {
    handleCurrentRow(row);
    handleSideMenu();
    // getManagerData(user?.userId);
  };

  useEffect(() => {
    dispatch(getUsersList());
  }, [dispatch]);

  useEffect(() => {
    if (!isSideMenu && data.length !== 0) {
      // setCurrentrow({});
      // isMultipleRowsSelected(false);
      // dispatch(usersActions.setMultipleSelected(false));
    }
  }, [isSideMenu]);

  return (
    <>
      <CustomModal
        style={{ width: "500px", height: "209px" }}
        open={isModal}
        setIsModal={setIsModal}
        handleModalClose={handleModalClose}
        handleModalConfirmation={handleModalConfirmation}
        users
        activeUsers
      />

      {/* Edit user should be activeUserSidebar */}
      {/* It should contain tab states */}
      {/* Tabs should further render as component ( Details, Survey etc ) */}

      {isSideMenu && <BackgroundBlur closeMenu={handleSideMenu} />}
      <EditUser
        // manager={manager}
        isSideMenuOpen={isSideMenu}
        currentrow={currentrow}
        handleSideMenu={handleSideMenu}
        handleArchiveUsers={handleArchiveUsers}
        handleArchiveEditMenu={handleArchiveEditMenu}
        handleArchiveModal={handleArchiveModal}
        setCurrentrow={setCurrentrow}
        activeUser
      />

      <Table
        data={data}
        headerBackgroundColor="#F7FBFF"
        columns={USERS_COLUMNS}
        loading={tableLoading}
        page={page}
        inputValue={inputValue}
        filters={filters}
        setTablePage={setTablePage}
        selected={selected}
        setSelected={setSelected}
        handleTableRowClick={handleTableRowClick}
        toolbarText={["Archive Selected Users", "Archive All Users"]}
        handleToolbarAction={handleToolbarAction}
        checkboxSize="24px"
        // displayAvatar
        checkboxRequired
      />
    </>
  );
}

export default ActiveUsers;
