import React, { useEffect } from "react";
import icon from "../../../assets/icons/Slack.svg";
import Button from "../../../components/sub-components/Button";
import { ReactComponent as Tick } from "../../../assets/icons/BlackTick.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../Login/Header";
import Footer from "../../../components/sub-components/Footer";
import { getTenantProfileSliceCall } from "../../../redux/slices/authSlice";

export default function AdmiminPermission() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authReducer.user);
  const tenantProfile = useSelector((state) => state.authReducer.tenantProfile);

  useEffect(() => {
    dispatch(getTenantProfileSliceCall(user.tenantId));
  }, [dispatch, user]);

  return (
    <>
      <div
        className="col-lg-10 col-md-8 d-flex flex-column align-items-center"
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          height: "100vh",
        }}
      >
        <Header />

        <div className="signup-flow-headers row">
          <div className="col-lg-12">
            <div className="head6 grey7">
              Your organization is already a part of Crewnetics. Please click on
              Get Started to continue.
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingTop: "50px" }}>
          <div className="col-lg-12">
            <div className="head5 grey9">Your organization’s workspace</div>
          </div>
        </div>

        <div
          className="row"
          style={{
            paddingTop: "20px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            className="col-lg-12 d-flex flex-row justify-content-between align-items-center"
            style={{
              maxWidth: "561px",
              minWidth: "290px",
              height: "110px",
              paddingLeft: "20px",
              paddingRight: "20px",
              backgroundColor: "#F8F9FA",
              borderRadius: "20px",
            }}
          >
            <div className="d-flex flex-row justify-content-between">
              <img src={tenantProfile?.image_url || icon} />
              <div
                className="d-flex flex-column align-items-between"
                style={{ paddingLeft: "25px", textAlign: "start" }}
              >
                <div className="head6">{tenantProfile?.tenant_name}</div>
                <div className="b1">
                  {tenantProfile?.user_profiles?.length > 1
                    ? `${tenantProfile?.user_profiles?.length} members`
                    : `1 member`}
                </div>
              </div>
            </div>
            <Tick style={{ width: "5%", height: "20%" }} />
          </div>
        </div>

        <div className="row" style={{ paddingTop: "50px" }}>
          <div className="col-lg-12" style={{ width: "230px" }}>
            <Link to="/identity-providers-list">
              <Button
                buttonHollowLg
                style={{ backgroundColor: "#7B2CBF", color: "white" }}
                title="Get Started"
              />
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
