import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as SidebarLogo } from "../../assets/icons/Sidebar/sidebar_logo.svg";
import { ReactComponent as SidebarLogoCollapse } from "../../assets/icons/Sidebar/crewnetics_logo_mobile.svg";

import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import * as Utils from "../../utilities/Utils.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserData } from "../../redux/slices/authSlice.js";
import { SIDEBARITEMS } from "../../utilities/Constants.js";
import fallbackSrc from "../../assets/icons/default_user.svg";

const Sidebar = (props, { defaultActive }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const anchorRef = React.useRef(null);
  const lastActiveIndexString = localStorage.getItem("lastActiveIndex");
  const lastActiveIndex = Number(lastActiveIndexString);

  const { role, userId } = useSelector((state) => state.authReducer.user);
  const userProfile = useSelector((state) => state.authReducer.userProfile);

  const [activeIndex, setActiveIndex] = useState(
    lastActiveIndex || defaultActive,
  );
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [collapsed, setCollapsed] = useState(false); // Set initial to not collapsed

  function changeActiveIndex(newIndex) {
    localStorage.setItem("lastActiveIndex", newIndex);
    setActiveIndex(newIndex);
  }

  function getPath(path) {
    if (path?.charAt(0) !== "/") {
      return "/" + path;
    }
    return path;
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = (event) => {
    setIsLoading(true);
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    Utils.deleteCookie("rftkn");
    Utils.deleteCookie("email");
    setTimeout(() => {
      history.push("/");
      setOpen(false);
      setIsLoading(false);
    }, 1000);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const activeItem = SIDEBARITEMS.findIndex(
      (item) => getPath(item.route) === getPath(location?.pathname),
    );
    changeActiveIndex(activeItem);
  }, [location]);

  useEffect(() => {
    if (Object.keys(userProfile).length === 0) {
      dispatch(getCurrentUserData(userId));
    }
  }, [userId]);

  console.log("userProfile.image_url", userProfile.image_url);

  return (
    <>
      <div
        className="sidebar-parent"
        style={{
          transition: "width 250ms ease",
          width: collapsed ? "74px" : "298px",
        }}
      >
        <div
          style={{
            position: "fixed",
            background: "linear-gradient(0deg, #27005d -35.97%, #9300ff 100%)",
            transition: "width 250ms ease",
            width: collapsed ? "74px" : "298px",
            height: "100vh",
          }}
        >
          {/* ----------- THIS NEEDS TO BE UPDATED IN SYNC WITH OTHER STUFF, FOR NOW LEAVING AS IT AS */}
          {collapsed ? (
            <SidebarLogoCollapse
              style={{
                margin: collapsed ? "27px 19px" : "27px",
                marginBottom: "40px",
              }}
            />
          ) : (
            <SidebarLogo
              style={{
                margin: collapsed ? "27px 19px" : "27px",
                marginBottom: "40px",
              }}
            />
          )}
          {/* Collapse/Expand Button */}
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }}
          >
            <button
              onClick={() => setCollapsed(!collapsed)}
              style={{
                marginRight: "-12px",
                borderRadius: "50px",
                background: "#5a1c86",
                border: "none",
                color: "white",
                cursor: "pointer",
                width: "30px",
                height: "30px",
              }}
            >
              <span className="head7 text-white">{collapsed ? ">" : "<"} </span>
            </button>
          </div>
          <div
            className="d-flex justify-content-between flex-column"
            style={{ height: "70vh", overflow: "hidden", overflowY: "auto" }}
          >
            <div>
              {SIDEBARITEMS.slice(0, 6).map((item, index) => (
                <Link
                  to={item.route}
                  key={item.name}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className={`d-flex sidebar-item ${collapsed && "collapsed"} ${
                      index === activeIndex ? "active" : ""
                    }`}
                    style={{ marginBottom: "16px" }}
                  >
                    <div className="d-flex align-items-center">
                      {React.cloneElement(item.image, {
                        stroke: index === activeIndex ? "black" : item.stroke,
                      })}
                    </div>

                    <div
                      className={`item-link body1 ml-4 ${
                        index === activeIndex && "activeItem"
                      }`}
                    >
                      {item.name}
                    </div>
                  </div>
                </Link>
              ))}
            </div>

            <div>
              {SIDEBARITEMS.slice(6, 8).map((item, index) => (
                <Link
                  to={item.route}
                  key={item.name}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className={`d-flex sidebar-item ${collapsed && "collapsed"} ${
                      index + 6 === activeIndex ? "active" : ""
                    }`}
                    style={{ marginBottom: "16px" }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ color: "black" }}
                    >
                      {React.cloneElement(item.image, {
                        stroke:
                          index + 6 === activeIndex ? "black" : item.stroke,
                      })}
                    </div>
                    <div
                      className={`item-link body1 ml-4 ${
                        index + 6 === activeIndex && "activeItem"
                      }`}
                    >
                      {item.name}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <div
            style={{
              borderBottom: "1px solid #FFFFFF33",
              margin: "32px 0",
              marginTop: collapsed ? "32px" : "42px",
              transition: "250ms ease",
            }}
          />
          <div
            className="flex-container d-flex align-items-center"
            style={{
              marginLeft: collapsed ? "18px" : "25px",
              transition: "250ms ease",
            }}
          >
            <div>
              <div
                className="flex-child magenta"
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                style={{ cursor: "pointer" }}
              >
                {userProfile.image_url ? (
                  <img
                    src={userProfile.image_url}
                    alt={`${userProfile.first_name} ${userProfile.last_name}`}
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = fallbackSrc;
                    }}
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <>
                    {userProfile.first_name?.substring(0, 1)}
                    {userProfile.last_name?.substring(0, 1)}
                  </>
                )}
              </div>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper sx={{ width: "80px" }}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleLogout}>
                            {!props.loading && (
                              <span
                                className={isLoading ? "loading-opacity" : ``}
                                style={{
                                  color: "black",
                                  pointerEvents: isLoading && "none",
                                }}
                              >
                                {"Logout"}
                              </span>
                            )}
                            {isLoading && (
                              <div
                                className={isLoading ? "dots-5" : "dots-5"}
                                style={{ marginLeft: "-30px" }}
                              />
                            )}
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>

            <div className="flex-child ml-2" style={{ flex: 1 }}>
              <div
                style={{ marginLeft: "10px", color: "white" }}
                className="s1"
              >
                {Object.keys(userProfile).length > 0 &&
                  userProfile.first_name + " " + userProfile.last_name}
              </div>
              <div style={{ marginLeft: "10px", color: "white" }}>{role}</div>
            </div>
          </div>
        </div>
        <div
          className="behind-the-scenes"
          style={{
            transition: "width 250ms ease",
            width: collapsed ? "74px" : "250px",
          }}
        />
      </div>
    </>
  );
};

export default Sidebar;
