import React, { useEffect, useState } from "react";
import { secure_instance } from "../../../axios/axios-config";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CaretDown } from "../../../assets/icons/CaretDown.svg";
import { ReactComponent as PerformanceIcon } from "../../../assets/icons/Views/Dashboard/Performance.svg";
import { ReactComponent as OkrsIcon } from "../../../assets/icons/Views/Dashboard/okrs.svg";
import { ReactComponent as SurveysIcon } from "../../../assets/icons/Views/Dashboard/surveys.svg";
import { DateRangePicker } from "../../../components/DatePickers/DateRangePicker";
import dayjs from "dayjs";

import AnalyticsCardNew from "./AnalyticsCardNew";
import { PieChartTest } from "../../../utilities/Loaders";
import DashboardOverviewGraphs from "../../../components/Analytics/DashboardOverview";
import BarGraph from "../../../components/Analytics/BarGraph";

import {
  lineGraphDataSingle,
  pieChartDummyData,
} from "../../../utilities/dummyGraphs/LineGraphData";
import PopperDropdown from "../../../components/DropDowns/PopperDropdown";

import ActiveTimePerTeam from "../../../components/Analytics/ActiveTimePerTeam";
import CommunicationTeam from "../../../components/Analytics/CommunicationTypeGraph";
import LineGraphTemp from "../../../components/Analytics/LineGraphTemp";
import {
  setDashboardDateRange,
  triggerReFetch,
  updateDateFilter,
  updateOrganizationFilter,
} from "../../../redux/slices/dashboardSlice";

const dummyBarData = [
  {
    country: "AD",
    "hot dog": 100,
  },
  {
    country: "AE",
    "hot dog": 79,
  },
  {
    country: "AF",
    "hot dog": 163,
  },
  {
    country: "AG",
    "hot dog": 60,
  },
  {
    country: "AI",
    "hot dog": 183,
  },
  {
    country: "AL",
    "hot dog": 84,
  },
  {
    country: "AM",
    "hot dog": 29,
  },
];

export default function Dashboard() {
  const dispatch = useDispatch();

  const dashboardGlobalFilters = useSelector(
    (state) => state.dashboardReducer.dashboardGlobalFilters,
  );
  const organizationGlobalFilter = useSelector(
    (state) => state.dashboardReducer.organizationGlobalFilter,
  );

  const [teamsData, setTeamsData] = useState([]); // teams to show inside dropdown
  const [cohortsData, setCohortsData] = useState([]); // cohorts to show inside dropdown
  const [usersData, setUsersData] = useState([]); // users to show inside dropdown

  const [globalFilterSearchValue, setGlobalFilterSearchValue] = useState("");
  const [isSearchMatch, setIsSearchMatch] = useState(true);
  const [globalSelectedFilter, setGlobalSelectedFilter] = useState("");
  const [globalSelectedCategory, setGlobalSelectedCategory] = useState("");
  // const [recentSearches, setRecentSearches] = useState([]);
  const [isRefreshAnalytics, setIsRefreshAnalytics] = useState(false);
  const [globalFilterChanged, setGlobalFilterChanged] = useState(false);
  const [globalFilteriDs, setGlobalFilteriDs] = useState([]);

  const [pieChartLegend, setPieChartLegend] = useState([]);
  const [organizationItemSelected, setOrganizationItemSelected] = useState({});
  const [organizationItemApplied, setOrganizationItemApplied] = useState({});
  const [organizationData, setOrganizationData] = useState([]);
  const [isTeamSelected, setIsTeamSelected] = useState(false);

  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [endDate, setEndDate] = useState(
    dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);

  const [isNestedFilterationMenuOpen, setIsNestedFilterationMenuOpen] =
    React.useState(false);
  const nestedFilterAnchorEl = React.useRef(null);

  const fetchDataForGlobalFilteration = async () => {
    if (
      teamsData.length === 0 &&
      cohortsData.length === 0 &&
      usersData.length === 0
    ) {
      try {
        const teams = await secure_instance.request({
          url: `v1/teams/`,
          method: "GET",
        });
        const cohorts = await secure_instance.request({
          url: `v1/cohorts/`,
          method: "GET",
        });
        const userProfile = await secure_instance.request({
          url: `v1/user_profile/?type=all`,
          method: "GET",
        });
        const usersFormatConverted = userProfile.data?.map((obj) => {
          if (Object.prototype.hasOwnProperty.call(obj, "user_id")) {
            obj["name"] = `${obj.first_name} ${obj.last_name}`;
            return obj;
          }
        });

        setTeamsData(teams.data);
        setCohortsData(cohorts.data);
        setUsersData(usersFormatConverted);

        setOrganizationData([
          ...teams.data,
          ...cohorts.data,
          ...usersFormatConverted,
        ]);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleNestedFilterationMenuOpen = () => {
    setIsNestedFilterationMenuOpen((prevOpen) => !prevOpen);
  };
  const handleNestedFilterationMenuClose = () => {
    setIsNestedFilterationMenuOpen(false);
  };

  const handleClickCheckbox = (item) => {
    if (item === organizationItemSelected) {
      setOrganizationItemSelected({});
    } else {
      setOrganizationItemSelected(item);
    }
  };

  const handleCancelOrganizationFilter = () => {
    handleNestedFilterationMenuClose();
    setOrganizationItemSelected(organizationItemApplied);
  };

  useEffect(() => {
    if (isTeamSelected) {
      setOrganizationData([...teamsData, ...cohortsData]);
    } else {
      setOrganizationData([...teamsData, ...usersData, ...cohortsData]);
    }
  }, [isTeamSelected]);
  useEffect(() => {
    const isTeamItemSelected = Object.prototype.hasOwnProperty.call(
      organizationItemApplied,
      "group_id",
    );
    if (isTeamItemSelected) {
      setIsTeamSelected(true);
    } else {
      setIsTeamSelected(false);
    }
  }, [organizationItemApplied]);

  const handleApplyOrganizationFilter = () => {
    setOrganizationItemApplied(organizationItemSelected);
    handleNestedFilterationMenuClose();

    const organizationGlobalFilterClone = organizationGlobalFilter;

    const userFilters = organizationGlobalFilterClone;

    if (Object.keys(organizationItemSelected).length === 0) {
      userFilters.filters = [];
    } else if (userFilters) {
      userFilters.filters = [
        {
          col: "group_id",
          operator: "==",
          value: organizationItemSelected.group_id,
        },
      ];
    }

    dispatch(updateOrganizationFilter(organizationGlobalFilterClone));

    dispatch(triggerReFetch(true));
    setTimeout(() => {
      dispatch(triggerReFetch(false));
    }, 1000);
  };

  const fetchDatesData = async (selectedStartDate, selectedEndDate) => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);

    const dashboardGlobalFiltersClone = [...dashboardGlobalFilters];

    const updatedDateFilter = {
      col: "event_time",
      operator: "between",
      value: [
        dayjs(selectedStartDate).format("YYYY-MM-DD HH:mm:ss"),
        dayjs(selectedEndDate).format("YYYY-MM-DD HH:mm:ss"),
      ],
    };

    dashboardGlobalFiltersClone[0] = updatedDateFilter;

    dispatch(
      setDashboardDateRange({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      }),
    );
    dispatch(triggerReFetch(true));
    setTimeout(() => {
      dispatch(triggerReFetch(false));
    }, 1000);
    dispatch(updateDateFilter(dashboardGlobalFiltersClone));
  };

  useEffect(() => {
    fetchDataForGlobalFilteration();
  }, []);

  return (
    <>
      <div>
        <div
          className="page-body-new d-flex justify-content-between"
          style={{ padding: "0 24px 44px 27px" }}
        >
          <div className="col-12" style={{ width: "100%" }}>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ marginBottom: "32px" }}
            >
              <PopperDropdown
                anchorRef={nestedFilterAnchorEl}
                isDropdownOpen={isNestedFilterationMenuOpen}
                handleToggle={() => handleNestedFilterationMenuOpen()}
                handleClose={() => handleCancelOrganizationFilter()}
                dropdownDisplayText="Users"
                customRenderFieldChildren={
                  <div
                    className="d-flex align-items-center head4-semi-bold"
                    style={{ cursor: "pointer" }}
                  >
                    Organization{" "}
                    <CaretDown
                      stroke="black"
                      stroke-width="3"
                      className="ml-2"
                    />
                  </div>
                }
                dropdownWithSearchAndCheckbox
                dropdownData={organizationData}
                handleClickCheckbox={handleClickCheckbox}
                handleApply={handleApplyOrganizationFilter}
                handleCancel={handleCancelOrganizationFilter}
                dropdownItemsSelected={organizationItemSelected}
                keyToAccess="name"
                // handleOptionSelected={handleGroupBySelected}
                // activeMenuItem={"Users"}
              />

              <div
                className="d-flex align-items-center"
                style={{
                  height: "36px",
                  background: "#F8F9FA",
                  border: "1px solid #E9ECEF",
                  borderRadius: "10px",
                  padding: "6px",
                  cursor: "pointer",
                }}
              >
                <DateRangePicker
                  value={{ from: startDate, to: endDate }}
                  fetchDatesData={fetchDatesData}
                  setIsDateMenuOpen={setIsDateMenuOpen}
                  isDateMenuOpen={isDateMenuOpen}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 ">
                <DashboardOverviewGraphs
                  icon={<PerformanceIcon />}
                  graphType={"Performance"}
                  quantifyText={"+2.1% Increase"}
                  time={"Last 30 Days"}
                  bottomText={"Compared to month of April"}
                  graph={
                    <BarGraph
                      groupByGraphKeys={["hot dog"]}
                      graphData={dummyBarData}
                      currentFilters={[]}
                      graphName={"Perfor"}
                      indexBy={"country"}
                      hideDateOnToolTip={true}
                      tooltip={null}
                      padding={0.09}
                      colors={"#A790FF"}
                      isAxisBottomDisplay={false}
                      isAxisLeftDisplay={false}
                      enableGridX={false}
                      enableGridY={false}
                      borderRadius={5}
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                      // maxValue={300}
                      graphContainerHeight={"106px"}
                    />
                  }
                />
              </div>

              <div className="col-lg-4 ">
                <DashboardOverviewGraphs
                  icon={<OkrsIcon />}
                  graphType={"Total OKR’s : 4"}
                  quantifyText={"Total key results Acheived: 8"}
                  time={"Last 30 Days"}
                  bottomText={"OKR’s In progress: 10"}
                  graph={
                    <LineGraphTemp
                      graphData={lineGraphDataSingle}
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                      graphContainerHeight={"106px"}
                      curve={"natural"}
                    />
                  }
                />
              </div>

              <div className="col-lg-4 ">
                <DashboardOverviewGraphs
                  icon={<SurveysIcon />}
                  graphType={"Total Surveys: 32"}
                  quantifyText={`Answered:12    Unanswered:20`}
                  time={"Last 30 Days"}
                  bottomText={pieChartDummyData?.map((data) => data.id)}
                  pieChart
                  graph={
                    <PieChartTest
                      data={pieChartDummyData}
                      setPieChartLegend={setPieChartLegend}
                    />
                  }
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <AnalyticsCardNew
                graph={"Productivity"}
                graphName={"Productivity"}
                teamsData={teamsData}
                usersData={usersData}
                cohortsData={cohortsData}
                globalFilterSearchValue={globalFilterSearchValue}
                isSearchMatch={isSearchMatch}
                setIsSearchMatch={setIsSearchMatch}
                globalSelectedCategory={globalSelectedCategory}
                globalSelectedFilter={globalSelectedFilter}
                globalFilteriDs={globalFilteriDs}
                isRefreshAnalytics={isRefreshAnalytics}
                setIsRefreshAnalytics={setIsRefreshAnalytics}
                globalFilterChanged={globalFilterChanged}
                setGlobalFilterChanged={setGlobalFilterChanged}
                indexBy={"user_id"}
                graphType="productivityGraph"
                productivityGraph
                isTeamSelected={isTeamSelected}
                userProfilesExtracted={
                  organizationItemApplied?.team_group &&
                  organizationItemApplied?.team_group[0]?.group_members.map(
                    (group) => group.user_profile,
                  )
                }
                organizationItemSelected={organizationItemSelected}
              />
            </div>

            <div className="row">
              <div className="col-lg-6">
                <ActiveTimePerTeam />
              </div>
              <div className="col-lg-6">
                <CommunicationTeam />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
