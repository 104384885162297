import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDispatch } from "react-redux";
import { CalendarIcon } from "@mui/x-date-pickers";
import TaskItem from "./TaskItem";
import SortableTaskItem from "./SortableTaskItem";
import { capitalizeFirstLetter } from "../../../../utilities/Utils";
import LinearProgressLoader from "../../../../components/sub-components/LinearProgress";
import {
  handleCurrentProgress,
  toggleSideMenu,
} from "../../../../redux/slices/ObjectivesSlice";

const BoardSection = ({ id, title, tasks }) => {
  const dispatch = useDispatch();

  const { setNodeRef } = useDroppable({
    id,
  });

  const getTopBorder = () => {
    if (title === "not started") {
      return "#EB2D1E";
    }
    if (title === "in progress") {
      return "#00B2FF";
    }
    if (title === "completed") {
      return "#00E132";
    }
  };

  const handleSaveCurrentProgressObjective = (task) => {
    dispatch(toggleSideMenu());
    dispatch(handleCurrentProgress(task));
  };

  return (
    <SortableContext
      id={id}
      items={tasks}
      strategy={verticalListSortingStrategy}
    >
      <div
        ref={setNodeRef}
        style={{
          background: "#FAFAFA",
          flex: 1,
          padding: "24px 20px",
          borderTop: `12px solid ${getTopBorder()}`,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          borderLeft: "1px solid #BFBFBF",
          borderRight: "1px solid #BFBFBF",
          borderBottom: "1px solid #BFBFBF",
          borderRadius: "10px",
        }}
      >
        <div className="d-flex justify-content-between mb-3">
          <div className="head7-bold">{capitalizeFirstLetter(title)}</div>

          <div className="head7-bold">{tasks.length}</div>
        </div>
        {tasks?.length
          ? tasks.map((task) => (
              <div
                key={task.id}
                className="d-flex"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSaveCurrentProgressObjective(task);
                }}
              >
                <SortableTaskItem id={task.id}>
                  <TaskItem task={task} />
                </SortableTaskItem>
              </div>
            ))
          : ""}
        <div
          className="card p-2 mt-3"
          style={{
            border: "1px solid #DEDEDE",
            borderRadius: "10px",
            boxShadow: "2px 2px 15px 0px #DEDEDE",
            position: "relative",
            zIndex: -1,
          }}
        >
          <div className="mb-2 head6-medium d-flex">
            <div className="ml-auto"></div>
          </div>
          <div
            style={{
              width: "85%",
              paddingLeft: "27px",
              paddingTop: "16px",
              paddingBottom: "32px",
            }}
          >
            <div className="mb-1 head6-medium">
              Hire And Retain The Best Employee
            </div>
            <span className="caption2">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit anim id es. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id es. As a company we need to structure and make moves to
              capitalize on...........
            </span>
            <div style={{ padding: "24px 0" }}>
              <LinearProgressLoader variant="determinate" value={40} />
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <div className="body2-medium align-items-center">
                <span>
                  <CalendarIcon
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "8px",
                      marginBottom: "3px",
                    }}
                  />
                </span>
                <span style={{ color: "#00A926" }}>Q2</span>
                <span>/</span>
                <span style={{ color: "#EB2D1E" }}>Q4</span>
              </div>
              <div>40% complete</div>
            </div>
          </div>
        </div>
      </div>
    </SortableContext>
  );
};

export default BoardSection;
