import { BOARD_SECTIONS } from "./Constants";

export const initializeBoard = (tasks) => {
  const boardSections = {};
  Object.keys(BOARD_SECTIONS).forEach((boardSectionKey) => {
    boardSections[boardSectionKey] = getTasksByStatus(tasks, boardSectionKey);
  });
  return boardSections;
};

export const findBoardSectionContainer = (boardSections, id) => {
  if (id in boardSections) {
    return id;
  }

  const container = Object.keys(boardSections).find((key) =>
    boardSections[key].find((item) => item.id === id),
  );
  return container;
};

export const getTasksByStatus = (tasks, status) => {
  return tasks.filter((task) => {
    if (status === "not started" && task.overall_current_progress === 0) {
      return true;
    } else if (
      status === "in progress" &&
      task.overall_current_progress > 0 &&
      task.overall_current_progress < 100
    ) {
      return true;
    } else if (
      status === "completed" &&
      task.overall_current_progress === 100
    ) {
      return true;
    }
    return false;
  });
};

export const getTaskById = (tasks, id) => {
  return tasks.find((task) => task.id === id);
};
