import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as CaretDown } from "../../../assets/icons/CaretDown.svg";
import { DateRangePicker } from "../../../components/DatePickers/DateRangePicker";
import dayjs from "dayjs";
import PopperDropdown from "../../../components/DropDowns/PopperDropdown";
import Button from "../../../components/sub-components/Button";
import Field from "../../../components/sub-components/Field";
import BoardSectionList from "./components/BoardSectionList";
import { useDispatch, useSelector } from "react-redux";
import { getTeamsData } from "../../../redux/slices/teamsSlice";
import {
  getObjectiveData,
  toggleSideMenu,
} from "../../../redux/slices/ObjectivesSlice";
import ObjectivesSideMenu from "./ObjectivesMenu";

const Objectives = () => {
  const dispatch = useDispatch();

  const [teamsData, setTeamsData] = useState([]); // users to show inside dropdown
  const [cohortsData, setCohortsData] = useState([]); // users to show inside dropdown
  const [usersData, setUsersData] = useState([]); // users to show inside dropdown
  const [organizationItemSelected, setOrganizationItemSelected] = useState({});
  const [organizationItemApplied, setOrganizationItemApplied] = useState({});
  const [organizationData, setOrganizationData] = useState([]);
  const [isTeamSelected, setIsTeamSelected] = useState(false);
  const [filterInputValue, setFilterInputValue] = useState("");
  const [userSelected, setUserSelected] = useState(null);
  const [userApplied, setUserApplied] = useState(null);
  const [isCurrentViewMenuOpen, setIsCurrentViewMenuOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [endDate, setEndDate] = useState(
    dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);
  const [currentrow, setCurrentrow] = useState({});
  const [isNestedFilterationMenuOpen, setIsNestedFilterationMenuOpen] =
    useState(false);

  const currentViewAnchorEl = useRef(null);
  const nestedFilterAnchorEl = useRef(null);

  const { data } = useSelector((state) => state.teamsReducer.tableData);
  const { objectivesData } = useSelector((state) => state.objectivesReducer);

  const handleNestedFilterationMenuOpen = () => {
    setIsNestedFilterationMenuOpen((prevOpen) => !prevOpen);
  };
  const handleNestedFilterationMenuClose = () => {
    setIsNestedFilterationMenuOpen(false);
  };
  const handleApplyUserType = () => {
    setUserApplied(userSelected);
    handleNestedFilterationMenuClose();
  };

  const handleCancelUserType = () => {
    setUserSelected(userApplied);
    handleNestedFilterationMenuClose();
  };

  const handleClickCurrentViewOption = (item) => {
    if (item === organizationItemSelected) {
      setOrganizationItemSelected({});
    } else {
      setOrganizationItemSelected(item);
    }
  };

  const handleClickCheckbox = (item) => {
    setUserSelected(item);
  };

  const handleCancelOrganizationFilter = () => {
    handleNestedFilterationMenuClose();
    setOrganizationItemSelected(organizationItemApplied);
  };

  const handleGenericMenuOpen = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen((prevOpen) => !prevOpen);
  };
  const handleGenericMenuClose = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen(false);
  };

  useEffect(() => {
    if (isTeamSelected) {
      setOrganizationData([...teamsData, ...cohortsData]);
    } else {
      setOrganizationData([...teamsData, ...usersData, ...cohortsData]);
    }
  }, [isTeamSelected]);

  useEffect(() => {
    const isTeamItemSelected = Object.prototype.hasOwnProperty.call(
      organizationItemApplied,
      "group_id",
    );
    if (isTeamItemSelected) {
      setIsTeamSelected(true);
    } else {
      setIsTeamSelected(false);
    }
  }, [organizationItemApplied]);

  const handleApplyOrganizationFilter = () => {
    setOrganizationItemApplied(organizationItemSelected);
    handleNestedFilterationMenuClose();
  };

  const fetchDatesData = async (selectedStartDate, selectedEndDate) => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
  };

  const handleCreateNewObjective = () => {
    dispatch(toggleSideMenu());
  };

  useEffect(() => {
    dispatch(getTeamsData());
    dispatch(getObjectiveData());
  }, []);

  return (
    <div
      className="page-body-new"
      style={{ display: "block", padding: "0 24px 44px 27px" }}
    >
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-between">
            <PopperDropdown
              anchorRef={currentViewAnchorEl}
              isDropdownOpen={isCurrentViewMenuOpen}
              handleToggle={() =>
                handleGenericMenuOpen(setIsCurrentViewMenuOpen)
              }
              handleClose={() =>
                handleGenericMenuClose(setIsCurrentViewMenuOpen)
              }
              dropdownDisplayText="Users"
              customRenderFieldChildren={
                <div
                  className="d-flex align-items-center head4-semi-bold"
                  style={{ cursor: "pointer" }}
                >
                  Organization{" "}
                  <CaretDown stroke="black" stroke-width="3" className="ml-2" />
                </div>
              }
              dropdownWithSearchAndCheckbox
              dropdownData={organizationData}
              handleClickCheckbox={handleClickCurrentViewOption}
              handleApply={handleApplyOrganizationFilter}
              handleCancel={handleCancelOrganizationFilter}
              dropdownItemsSelected={organizationItemSelected}
              keyToAccess="name"
            />

            <Button
              buttonAddtoTeam
              title="Objective"
              onClick={() => handleCreateNewObjective()}
              style={{
                boxShadow: "none",
                background: "#9300FF",
                padding: "0 36px",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-between align-items-center"
        style={{ marginBottom: "32px", padding: "0 10px" }}
      >
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 0" }}
        >
          {" "}
          <PopperDropdown
            anchorRef={nestedFilterAnchorEl}
            isDropdownOpen={isNestedFilterationMenuOpen}
            handleToggle={() => handleNestedFilterationMenuOpen()}
            handleClose={() => handleNestedFilterationMenuClose()}
            dropdownDisplayText="Users"
            customRenderFieldChildren={
              <div
                className="d-flex align-items-center body1"
                style={{
                  cursor: "pointer",
                  border: "1px solid #9300FF",
                  padding: "10px 12px 10px 12px",
                  borderRadius: "10px",
                  marginRight: "16px",
                }}
              >
                {userSelected === null ? "None" : userSelected}
                <CaretDown stroke="black" stroke-width="2" className="ml-2" />
              </div>
            }
            dropdownWithSearchAndCheckbox
            dropdownData={objectivesData}
            handleClickCheckbox={handleClickCheckbox}
            handleApply={handleApplyUserType}
            handleCancel={handleCancelUserType}
            dropdownItemsSelected={userSelected}
            searchTerm={filterInputValue}
            searchBy="name"
            keyToAccess={"name"}
          />
          <Field
            inputFieldFullSearchIcon
            placeholder={`Search`}
            value={filterInputValue}
            onChange={(e) => setFilterInputValue(e.target.value)}
            style={{
              width: "100%",
              height: "40px",
              paddingLeft: "52px",
              borderRight: "1px solid #DEDEDE",
              borderRadius: "10px",
            }}
          />
        </div>

        <div
          className="d-flex align-items-center"
          style={{
            height: "36px",
            background: "#F8F9FA",
            border: "1px solid #E9ECEF",
            borderRadius: "10px",
            padding: "6px",
            cursor: "pointer",
          }}
        >
          <DateRangePicker
            value={{ from: startDate, to: endDate }}
            fetchDatesData={fetchDatesData}
            setIsDateMenuOpen={setIsDateMenuOpen}
            isDateMenuOpen={isDateMenuOpen}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </div>
      </div>

      <ObjectivesSideMenu
        currentrow={currentrow}
        setCurrentrow={setCurrentrow}
      />

      <div className="row">
        <div className="col-12">
          <BoardSectionList data={objectivesData} />
        </div>
      </div>
    </div>
  );
};

export default Objectives;
